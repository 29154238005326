<template>
  <!-- NAME[epic=库管] 仓库盘点 -->
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item>
        <el-select
          v-model="form.depot_id"
          placeholder="选择仓库"
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in depotList"
            :key="index"
            :value="item.id"
            :label="item.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.check_id"
          placeholder="选择盘点人"
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in checkPeopleList"
            :key="index"
            :value="item.id"
            :label="item.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status_type"
          placeholder="选择状态"
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAdd">新增盘点</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleContinue($index, row)"
          >
            继续
          </el-button>
          <el-button
            v-if="row.status != 1"
            type="text"
            @click.native.prevent="handleView($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.status == 2"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            审核
          </el-button>

          <el-button
            v-if="row.status != 4"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
          <!-- <el-button
            v-if="row.status != 4"
            type="text"
            @click.native.prevent="handleprint($index, row)"
          >
            打印
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <continue ref="continue" @refresh="fetchData"></continue>
    <add ref="add" @refresh="fetchData"></add>
    <check ref="check"></check>
    <review ref="review" @refresh="fetchData"></review>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="PD"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, deleteRow, review, saveCheck } from '@/api/depotCheck'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import { jbrSelect } from '@/api/purchase'
  import Continue from './components/continue'
  import Add from './components/add'
  import Check from './components/check'
  import Review from './components/review'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'

  export default {
    name: 'DepotInventory',
    components: { Continue, Add, Check, Review, RuilangDisplayDialog },
    data() {
      return {
        demoShow: true,
        data_id: 0,
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        depotList: [],
        time: [],
        checkPeopleList: [],
        statusList: [
          {
            id: 1,
            name: '盘点中',
          },
          {
            id: 2,
            name: '未审核',
          },
          {
            id: 3,
            name: '已审核',
          },
          {
            id: 4,
            name: '已作废',
          },
        ],
        form: {
          depot_id: '', //仓库id
          pageNo: 1, //页数,默认第一页
          pageSize: 10, //条数,默认10条
          start_time: '', //开始时间
          end_time: '', //结束时间
          status_type: '', //状态1盘点中2未审核3已审核
          check_id: '', //盘点人id
          is_close: '', //0正常1已废弃
          type: '1', //1仓 2车
        },
        list: [],
        checkList: ['盘点单号', '仓库', '盘点人', '盘点时间', '状态', '备注'],
        columns: [
          {
            order: 1,
            prop: 'code',
            label: '盘点单号',
            width: '',
          },
          {
            order: 2,
            prop: 'depot_name',
            label: '仓库',
            width: '',
          },
          {
            order: 3,
            prop: 'check_name',
            label: '盘点人',
            width: '',
          },
          {
            order: 4,
            prop: 'create_at',
            label: '盘点时间',
            width: '',
          },
          {
            order: 5,
            prop: 'status_txt',
            label: '状态',
            width: '',
          },
          {
            order: 6,
            prop: 'remark',
            label: '备注',
            width: '220',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      // 仓库下拉
      getAllSelect2().then((res) => {
        console.log(res)
        this.depotList = res.data
      })
      // 盘点人下拉
      jbrSelect().then((res) => {
        this.checkPeopleList = res.data.rows
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true

        let { data, msg, code, totalCount } = await getList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleContinue(index, row) {
        this.$refs.continue.check_id = row.id
        this.$refs.continue.showDialog = true
      },
      handleReview(index, row) {
        this.$refs.review.form.check_id = row.id
        this.$refs.review.showDialog = true
        // review({ check_id: row.id }).then((res) => {
        //   console.log(res)
        //   this.$message.success(res.msg)
        //   this.fetchData()
        // })
      },
      handleView(index, row) {
        this.$refs.check.form.check_id = row.id
        this.$refs.check.showDialog = true
      },
      // 提交盘点
      handleSubmit() {
        let goods_data = []
        this.list.forEach((item) => {
          if (item.goods_name !== '') {
            goods_data.push({
              goods_id: item.goods_id,
              unit_data: item.unit_list,
            })
          }
        })
        console.log(goods_data)
        saveCheck({
          check_id: this.form.check_id,
          goods_data: JSON.stringify(goods_data),
        }).then((res) => {
          console.log(res)
          completeList({
            check_id: this.form.check_id,
          }).then((res) => {
            this.$message.success(res.msg)
            this.$emit('refresh')
            this.close()
          })
        })
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({
              check_id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('作废成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleprint(index, row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
