var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "盘点单",
        visible: _vm.showDialog,
        width: "1300px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
              attrs: { model: _vm.form, inline: "" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "depot_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "盘点仓库", clearable: "" },
                          model: {
                            value: _vm.form.depot_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depot_id", $$v)
                            },
                            expression: "form.depot_id",
                          },
                        },
                        _vm._l(_vm.depotList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.depot_name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "check_user_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "选择盘点人", clearable: "" },
                          model: {
                            value: _vm.form.check_user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "check_user_id", $$v)
                            },
                            expression: "form.check_user_id",
                          },
                        },
                        _vm._l(_vm.checkPeopleList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.user_id,
                              label: item.user_name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleStart },
                        },
                        [_vm._v("开始盘点")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c("class-select", {
                        ref: "classSelect",
                        staticStyle: { width: "140px", "margin-right": "20px" },
                        on: { "class-select-change": _vm.chooseClass },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("brand-select", {
                        ref: "brandSelect",
                        staticStyle: { width: "140px" },
                        on: { "brand-select-change": _vm.chooseBrand },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("goods-search", {
                        ref: "goodsSearch",
                        attrs: { "is-table": false },
                        on: { "select-goods-all": _vm.selectGoodsId },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { stripe: "", data: _vm.pageData, height: "400px" },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      item.prop == "goods_name"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                row.isAdd
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          ref: "goodsSearchTable",
                                          refInFor: true,
                                          attrs: { "f-key": row.goods_name },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows($event, $index)
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoodsT(
                                                $event,
                                                row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          }
                        : item.prop == "check_num"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form",
                                  { attrs: { inline: "" } },
                                  _vm._l(
                                    row.unit_list,
                                    function (unitItem, unitIndex) {
                                      return _c(
                                        "el-form-item",
                                        { key: unitIndex },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              onkeyup:
                                                "value=value.replace(/[^\\-?\\d.]/g,'')",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateRowDelay(
                                                  $event,
                                                  row
                                                )
                                              },
                                              focus: function ($event) {
                                                return _vm.handleFocus(
                                                  $event,
                                                  unitItem
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.handleBlur(
                                                  $event,
                                                  unitItem
                                                )
                                              },
                                            },
                                            model: {
                                              value: unitItem.num,
                                              callback: function ($$v) {
                                                _vm.$set(unitItem, "num", $$v)
                                              },
                                              expression: "unitItem.num",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(unitItem.unit_name) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          }
                        : {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(" " + _vm._s(row[item.prop]) + " "),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              "page-sizes": [10, 50, 100, 200],
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "left" },
              attrs: { type: "success" },
              on: { click: _vm.handleImport },
            },
            [_vm._v(" 使用模板导入盘点数量 ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.handleExport } }, [
            _vm._v("导出"),
          ]),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm.showSub
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(" 提交盘点 ")]
              )
            : _vm._e(),
          _c("import-tpl", {
            ref: "importTpl",
            on: { refresh: _vm.fetchData },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }